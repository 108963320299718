import * as React from "react";
import { useRoutes } from "react-router-dom";
import DashboardLayout from "../Layouts/Dashboard";
import Home from "../Containers/Home";
import InternalPage from "../Containers/InternalPage";
import ContactUs from "../Containers/ContactUs";
import ContactUsBasic from "../Containers/ContactUsBasic";
import Booking from "../Containers/Booking";
import AllBlogs from "../Containers/AllBlogs";
import ViewBlog from "../Containers/AllBlogs/ViewBlog";

function Router() {
  let element = useRoutes([
    {
      path: "/",
      // element: <Login />,
      element: <DashboardLayout />,
      children: [
        { path: "", element: <Home /> },
        { path: "startup-compliance", element: <InternalPage /> },
        { path: "vpob-apob", element: <InternalPage /> },
        { path: "gst-advisory", element: <InternalPage /> },
        { path: "fixed-desk", element: <InternalPage /> },
        { path: "contact-us-basic", element: <ContactUsBasic /> },
        { path: "contact-us", element: <ContactUs /> },
        { path: "booking", element: <Booking /> },
        { path: "blogs", element: <AllBlogs /> },
        { path: "blogs/view/:id", element: <ViewBlog /> },
      ],
    },
    // {
    //   path: "*",
    //   element: <Navigate to="/" />,
    // },
  ]);

  return element;
}

export default Router;
