import React from "react";
import "./index.scss";
import Column1 from "./Column1";
import Column2 from "./Column2";
import Paragraph from "antd/es/typography/Paragraph";
const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-container">
        <Column1 />
        <Column2 />
      </div>
      <div className="copyright-container">
        <Paragraph className="footer-copyright typography-reset-margin">
          ALL RIGHTS RESERVED
        </Paragraph>
      </div>
    </div>
  );
};

export default Footer;
