import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import { foursteps, landingServiceData, threeSteps } from "./staticsData";
import girl1 from "../Assets/Images/girl_1.png";
import girl2 from "../Assets/Images/girl_2.png";
import girl3 from "../Assets/Images/girl_3.png";
import girl4 from "../Assets/Images/girl_4.png";
import banner1 from "../Assets/Images/banner-1.jpg";
import banner2 from "../Assets/Images/banner-2.jpg";
import banner3 from "../Assets/Images/banner-3.jpg";
import banner4 from "../Assets/Images/banner-4.jpg";
import { fancyUlTextRenderer } from "./renderer";
import fancyLine from "../Assets/Icons/styledLine.svg";
import { setSummaryPlanPrice } from "../Redux/Slices/PlanSlice";

export const pathChecker = (location, pathname) => {
  if (location === pathname) return true;
  else return false;
};

export const bannerImageChecker = (pathname) => {
  switch (pathname) {
    case "/startup-compliance":
      return banner1;
    case "/vpob-apob":
      return banner4;
    case "/gst-advisory":
      return banner3;
    case "/fixed-desk":
      return banner2;
    default:
      return "internal-banner-1.jpg";
  }
};
export const bannerChecker = (pathname) => {
  switch (pathname) {
    case "/startup-compliance":
      return (
        <>
          <Title level={4} className="compliance-banner-upper-text">
            Startup and Compliance
          </Title>
          <Title
            level={1}
            className="compliance-banner-middle-text  typography-reset-margin"
          >
            Your partner for startup compliance and{" "}
            {fancyUlTextRenderer(
              fancyLine,
              "bold",
              "regulatory success.",
              "#2F2F30"
            )}
          </Title>
          <Paragraph className="compliance-banner-description text-center banner-description font-300 text-16 text-black  typography-reset-margin">
            Our comprehensive suite of services covers every aspect of business
            setup and regulatory compliance, ensuring you're set up for success
            from day one. Whether you're in need of Accounting Solutions, GST
            Compliance, Company Registration, E-commerce Payment Reconciliation,
            FSSAI or any other essential compliances, we've got you covered.
          </Paragraph>
        </>
      );
    case "/vpob-apob":
      return (
        <>
          <Title level={4} className="compliance-banner-upper-text ">
            VPOB & APOB
          </Title>
          <Title
            level={1}
            className="compliance-banner-middle-text  typography-reset-margin"
          >
            Your GST -ready virtual{" "}
            {fancyUlTextRenderer(
              fancyLine,
              "bold",
              "office spaces.",
              "#2F2F30"
            )}
          </Title>
          <Paragraph className="compliance-banner-description text-center banner-description font-300 text-16 text-black  typography-reset-margin">
            we are highly focused on serving ecommerce sellers with taxation,
            compliance and legal services. Get VPOB and Scale your Ecommerce
            Revenue and Reach Now
          </Paragraph>
        </>
      );
    case "/gst-advisory":
      return (
        <>
          <Title level={4} className="compliance-banner-upper-text">
            GST Advisory
          </Title>
          <Title
            level={1}
            className="compliance-banner-middle-text  typography-reset-margin"
          >
            Effortless{" "}
            {fancyUlTextRenderer(fancyLine, "bold", "GST solutions", "#2F2F30")}{" "}
            for every business.
          </Title>
          <Paragraph className="compliance-banner-description text-center banner-description font-300 text-16 text-black  typography-reset-margin">
            we are highly focused on serving businesses in terms of GST Review,
            GST Litigation, Obtaining Refunds, GST Compliance and Representation
            before the GST Department.
          </Paragraph>
        </>
      );
    case "/fixed-desk":
      return (
        <>
          <Title level={4} className="compliance-banner-upper-text ">
            Fixed Desk
          </Title>
          <Title
            level={1}
            className="compliance-banner-middle-text  typography-reset-margin"
          >
            Complete compliance
            <br />
            {fancyUlTextRenderer(
              fancyLine,
              "bold",
              "Total convenience.",
              "#2F2F30"
            )}
          </Title>
        </>
      );
    default:
      break;
  }
};

export const toggleTextVisibility = (
  index,
  setData,
  setShowServiceCard,
  showServiceCard,
  manual
) => {
  const indexUpdated = index === 4 ? 1 : index + 1;
  // const indexManual = index === 4 ? 1 : index;
  switch (manual ? index : indexUpdated) {
    case 1:
      setData(landingServiceData.startup);
      break;
    case 2:
      setData(landingServiceData.apob);
      break;
    case 3:
      setData(landingServiceData.gst);
      break;
    case 4:
      setData(landingServiceData.fixedDesk);
      break;
    default:
      setData(landingServiceData.startup);
  }
  if (showServiceCard !== index) {
    setShowServiceCard(index);
  }
};

export const renderGirl = (index) => {
  switch (index) {
    case 1:
      return girl1;
    case 2:
      return girl2;
    case 3:
      return girl3;
    case 4:
      return girl4;
    default:
      return girl1;
  }
};

export const serviceFloaterDynamicPosition = (position, isMobile) => {
  const positionToUse = {
    xs: {
      right: position.xs.x,
      top: position.xs.y,
    },
    md: {
      right: position.md.x,
      top: position.md.y,
    },
  };
  if (isMobile) return positionToUse.xs;
  else return positionToUse.md;
};

export const officeSpaceSayScroll = (e) => {
  const officeSpace = document.querySelector("#office-space-left");
  const officeSpaceContainer = document.querySelector(
    ".office-space-container"
  );
  const containerTop = officeSpaceContainer?.getBoundingClientRect().top;
  if (containerTop <= 0 && containerTop >= -3964) {
    officeSpace?.classList.add("is-sticky-client-say");
    officeSpace?.classList.remove("office-space-container");
    officeSpace?.classList.remove("office-space-container-bottom");
  } else if (containerTop > 0) {
    officeSpace?.classList.remove("is-sticky-client-say");
    officeSpace?.classList.add("office-space-left-container");
    officeSpace?.classList.remove("office-space-container-bottom");
  } else if (containerTop < -3964) {
    officeSpace?.classList.remove("is-sticky-client-say");
    officeSpace?.classList.remove("office-space-left-container");
    officeSpace?.classList.add("office-space-container-bottom");
  }
};

export const isStickyNav = (e) => {
  const header = document.querySelector(".navbar-container");
  const loginBtn = document.querySelector(".login-nav-btn");
  const scrollTop = window.scrollY;
  if (scrollTop >= 100) {
    header?.classList.add("is-sticky");
    loginBtn?.classList.add("is-outlined-btn");
  } else {
    header?.classList.remove("is-sticky");
    loginBtn?.classList.remove("is-outlined-btn");
  }
};

export const autoScroll = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};

export const additionalStepRender = (selectedPlan) => {
  // debugger;
  if (selectedPlan.additionalStep) {
    const tempFourSteps = [...foursteps];
    tempFourSteps.splice(1, 0, {
      title: "Step 2",
      description: selectedPlan.additionalStepTitle,
      disabled: true,
    });
    return tempFourSteps;
  } else return threeSteps;
};

export const currencyFormatter = (value) =>
  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const planPriceBasedUponState = (
  selectedState,
  selectedService,
  planName,
  statePricing,
  price,
  isNum
) => {
  if (statePricing) {
    let price = 0;
    selectedService.plans.map((plan) => {
      if (plan.name === planName) {
        if (plan.states) {
          return plan?.states?.map((state) => {
            if (state?.state?.name === selectedState?.state?.name) {
              price = isNum ? state?.price : currencyFormatter(state?.price);
            }
          });
        }
      }
    });
    return price;
  } else return price;
};

export const calculateTotal = (planPrice, addons) => {
  let total = planPrice;
  if (addons?.length > 0) {
    addons?.map((addOn) => {
      if (addOn.price) {
        total += addOn.price;
      }
    });
  }
  return total;
};

export const taxCalculator = (total, tax) => {
  return (tax / 100) * total;
};
export const grandTotalCalculator = (total, tax) => {
  return total + tax;
};

export const groupedArray = (arr) =>
  arr?.reduce((acc, obj) => {
    // Find the group by heading
    console.log("acc in helper", acc);
    let group = acc?.find((item) => item.title === obj.heading);

    if (!group) {
      // Create a new group if not found
      group = { title: obj.heading, data: [] };
      acc.push(group);
    }

    // Add the object to the `data` property of the group
    const { heading, ...rest } = obj;
    group?.data?.push(rest);

    return acc;
  }, []);

export const checkDefaultAddon = (currentId, summary) => {
  let temp = false;
  summary?.addons?.map((addOn) => {
    if (addOn.id === currentId) {
      temp = true;
    }
  });
  return temp;
};
